// MessageContext.js

/**
 * * USAGE:
 * import { useEffect } from 'react';
 * import { useMessager } from '../lib/context/MessageContext';
 *
 * export default function SomeComponent() {
 * 	const messager = useMessager();
 *
 * 	useEffect(() => {
 * 		messager.success('Hello World');
 * 	}, []);
 *
 * 	return <div>Some Component</div>;
 * }
 * * ------------------------------------------------
 */

import { createContext, useContext, useEffect, useState } from 'react';
import { message } from 'antd';

const MessageContext = createContext();

class Messager {
	constructor(messageProvider, setText) {
		this._message = messageProvider;
		this._textSetter = setText;
	}

	success(text, duration = 3, onClose = null) {
		this._textSetter(text);
		this._message.success(text, duration, onClose);
	}

	error(text, duration = 3, onClose = null) {
		this._textSetter(text);
		this._message.error(text, duration, onClose);
	}

	info(text, duration = 3, onClose = null) {
		this._textSetter(text);
		this._message.info(text, duration, onClose);
	}

	warn(text, duration = 3, onClose = null) {
		this._textSetter(text);
		this._message.warn(text, duration, onClose);
	}

	loading(text, duration = 3, onClose = null) {
		this._textSetter(text);
		this._message.loading(text, duration, onClose);
	}

	destroy() {
		this._textSetter(null);
		this._message.destroy();
	}
}

export const MessageProvider = ({ children }) => {
	const [text, setText] = useState(null);
	const [messager, setMessager] = useState(null);

	useEffect(() => {
		setMessager(new Messager(message, setText));
	}, []);

	return <MessageContext.Provider value={{ text, messager }}>{children}</MessageContext.Provider>;
};

export function useMessager() {
	const { messager } = useContext(MessageContext);
	return messager;
}
