/**
 * * Summary - This component wraps the pages' layouts and prevents unauthorized users from accessing.
 */
import { useUser } from '@auth0/nextjs-auth0';

import useLogRocket from '../../lib/hooks/useLogRocket';
import SessionExpired from '../../pages/session-expired';
import Unauthorized from '../../pages/unauthorized';
import Spin from '../ui/Spin';
import { BasicLogin } from './BasicLogin';

export default function Layout({ children }) {
	const { user, error, isLoading } = useUser();
	const { applyLogRocketUser } = useLogRocket();

	if (isLoading) return <Spin />;

	if (error) return <div>{error.message}</div>;

	if (!user) return <BasicLogin />;

	if (process.env.NEXT_PUBLIC_ENV !== 'development') {
		console.log('LogRocket :: registering user');
		applyLogRocketUser(user);
	}

	if (!user?.privileges || user.privileges.length === 0) return <Unauthorized />;

	if (!user?.org) return <SessionExpired />;

	if (user) {
		return children;
	}
}
