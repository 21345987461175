import { LockClosedIcon } from '@heroicons/react/outline';

export default function Unauthorized() {
	return (
		<div className="animate-fadeInPage flex flex-col justify-center h-screen w-screen bg-gray-300">
			<div className="flex justify-center content-center">
				<p className="font-bold text-xl">
					You are not authorized to use this application.
					<br />
					<span className="pl-10">
						Please contact{' '}
						<a className="text-sky-600" href="mailto:help@weioffice.com>?subject=AP Dashboard Access">
							Help
						</a>{' '}
						to request access.
					</span>
				</p>
			</div>
			<div className="flex justify-center mt-12">
				<a
					href="/api/auth/logout"
					title="Logout"
					className="text-black font-extrabold hover:text-white pt-2 h-12 w-20 flex"
				>
					<p className="font-bold  mt-1">Logout</p>
					<LockClosedIcon className="w-6 h-6 mx-auto" />
				</a>
			</div>
		</div>
	);
}
