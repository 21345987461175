import { createContext, useContext, useEffect, useRef } from 'react';
import Pusher from 'pusher-js';

// ! don't include this in production
if (process.env.NEXT_PUBLIC_ENV === 'development') {
	Pusher.logToConsole = true;
}

const PusherContext = createContext({
	notification: null,
	showNotification: () => {},
	hideNotification: () => {}
});

function PusherProvider({ children }) {
	const pusher = useRef(null);

	useEffect(() => {
		pusher.current = new Pusher(process.env.NEXT_PUBLIC_PUSHER_KEY, {
			cluster: 'us2'
		});
	}, []);

	return <PusherContext.Provider value={{ pusher }}>{children}</PusherContext.Provider>;
}

// Create custom hook for using the Pusher Context
// Fail fast if not within a PusherProvider
function usePusher() {
	const context = useContext(PusherContext);
	if (!context) {
		throw new Error('usePusher must be used within a PusherProvider');
	}

	const { pusher } = context;

	return pusher;
}

export { PusherProvider, usePusher };

// useEffect(() => {
// 	const pusher = new Pusher('dc9d7d3c2aa7877ceecc', { cluster: 'us2' });

// 	const handleApprovalError = (data) => {
// 		console.log('APPROVAL ERROR :: ', data);
// 		if (data.userName) {
// 			message.error('There was an issue updating this invoice in Business Central..');
// 			message.info(`Error Message: ${data.message}`);
// 		}
// 	};

// 	const handleApprovalSuccess = (data) => {
// 		console.log('APPROVAL SUCCESS :: ', data);
// 		if (data.userName) {
// 			message.success('Your approval has been successfully recorded in Business Central!');
// 		}
// 	};

// 	const handleApprovalBegin = (data) => {
// 		console.log('APPROVAL BEGIN :: ', data);
// 		if (data.userName) {
// 			message.info('Your approval is being processed in Business Central. This may take a moment');
// 		}
// 	};

// 	let mounted = true;

// 	if (mounted) {
// 		const channel = pusher.subscribe('ap-treasury-dashboard');
// 		channel.bind('post-treasury-approval-begin', handleApprovalBegin);
// 		channel.bind('post-treasury-approval-end', handleApprovalSuccess);
// 		channel.bind('post-treasury-approval-error', handleApprovalError);
// 	}

// 	return () => {
// 		pusher.unsubscribe('ap-treasury-dashboard');
// 		mounted = false;
// 	};
// }, []);
