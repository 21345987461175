import { LockClosedIcon } from '@heroicons/react/outline';

export default function SessionExpired() {
	return (
		<div className="animate-fadeInPage flex flex-col justify-center h-screen w-screen bg-gray-300">
			<div className="flex justify-center content-center">
				<div className="grid grid-cols-1 gap-y-10 text-2xl text-center">
					<div className="font-bold">
						Your session has expired
						<br />
						or you don&apos;t have an organization assigned.
					</div>
					<div>Please logout below and login again.</div>
					<div className="flex justify-center">
						<div>
							<a
								href="/api/auth/logout"
								title="Logout"
								className="rounded bg-sky-700 text-white px-4 py-3"
							>
								Logout
								<LockClosedIcon className="ml-4 -mt-1 w-5 h-5 inline-block" />
							</a>
						</div>
					</div>
					<div>
						Or contact{' '}
						<a className="text-sky-600" href="mailto:help@weioffice.com>?subject=AP Dashboard Access">
							Help
						</a>{' '}
						to request access.
					</div>
				</div>
			</div>
		</div>
	);
}
