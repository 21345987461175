import LogRocket from 'logrocket';

const useLogRocket = () => {
	const applyLogRocketUser = (user) => {
		LogRocket.identify(user.guid, {
			name: user.name,
			email: user.email
		});
	};

	return { applyLogRocketUser };
};

export default useLogRocket;
