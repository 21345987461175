export const BasicLogin = () => {
    return (
        <div className="grid place-items-center h-screen">
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
            <a
                href="/api/auth/login"
                className="rounded-lg bg-white border border-sky-800 text-sky-600 px-4 py-2 text-2xl font-bold m-auto"
            >
                Login
            </a>
        </div>
    );
  }